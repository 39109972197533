body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Open Sans', sans-serif;
    background-color: #f3f3f3;
    overflow-y: scroll;
}

.main-nav-bar {
    background-color: white;
}

a.nav-link.active {
    font-weight: 700;
    color: royalblue !important;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.smooth-color-trans {
    transition-duration: 200ms;
    transition-property: color;
}

.max-height-500px {
    max-height: 500px;
    overflow-y: auto;
}

.fs-14px {
    font-size: 14px;
}

.mt-4px {
    margin-top: 4px;
}

.react-datepicker__time-container--with-today-button {
    right: -84px !important;
}

.required:before {
    color: red;
    content: "*";
    font-size: 24px;
    line-height: 20px !important;
    font-weight: bold;
}

.minh-200px--maxh-30vh {
    min-height: 300px;
    height: 40vh;
    overflow-y: auto;
}

.spinner-100px {
    width: 100px;
    height: 100px;
}

.spinner-50px {
    width: 50px;
    height: 50px;
}


.gray-bg {
    background-color: #e7eaf6;
}

.error-place {
    min-height: 30px;
}

.table-header-centered th,
.table-header-centered td {
    vertical-align: middle;
}

.table-content-centered th,
.table-content-centered td {
    text-align: center;
}

.table-content-centered th {
    vertical-align: middle;
}

.unbreakable-cell-text td {
    white-space: nowrap;
}

.navbar-centered > a {
    text-align: center;
}

.th-sorted-by {
    text-decoration: underline;
}

.modal-body {
    word-break: break-all;
}

.selected-table-row {
    background-color: #beddec;
}

.selected-table-row-delete {
    background-color: #ff9999;
}

.input-min-width-80px {
    min-width: 80px;
}

.text-decoration-none {
    text-decoration: none;
}

.text-decoration-line-through {
    text-decoration: line-through;
}

.list-title {
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 0.2em;
}
/*xs*/
.modal-font-responsive {
    font-size: 0.8em;
}

.invisible-on-mobile {
    display: none;
}

.visible-on-mobile {
    display: inline;
}

.responsive-table-padding th,
.responsive-table-padding td {
    padding: 4px;
}

/*sm*/
@media (min-width: 576px) {
    .modal-font-responsive {
        font-size: 0.9em;
    }
}

/* md */
@media (min-width: 768px) {
    

}

/* lg */
@media (min-width: 992px) {
    .invisible-on-mobile {
        display: inline;
    }

    .visible-on-mobile {
        display: none;
    }

    .modal-font-responsive {
        font-size: 1em;
    }
}

/* xl */
@media (min-width: 1200px) {
    
}

/* xxl */
@media (min-width: 1400px) {
    
}

/*.new-order-form {
    padding-top: 510px;
}*/

.new-order-form{
    margin-bottom: 20px !important;
}

.summaryPanel {
    background-color: #cadefc !important;
}

.sticky-top.summaryPanel {
    top: 59px;
}

.fixed-row-bottom {
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 3;
}